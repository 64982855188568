.body-non-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
  
.body-non-login h1 {
  font-weight: 900;
  font-size: 2rem;
}