.youtube-uploader {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube-uploader form {
  display: flex;
  width: 100%;
}

.youtube-uploader input {
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid var(--color-lineNormal);
  border-radius: 20px 0 0 20px;
  outline: none;
}

.youtube-uploader button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.youtube-uploader button:hover {
  background-color: var(--color-main5);
}

.arrow-icon {
  font-size: 20px;
}

.video-preview {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  max-width: 560px;
}

.video-preview iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}