.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

.table-container {
  flex: 1;
  overflow-x: auto;
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.archive-table {
  width: 100%;
  min-width: 600px;
  background-color: var(--color-staticWhite);
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.archive-table th, .archive-table td {
  padding: 12px 15px;
  text-align: center;
  vertical-align: middle;
}

.archive-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 14px;
}

.archive-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.archive-table tr:hover {
  background-color: #e9ecef;
}

.archive-table td.video-title {
  width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download-button {
  padding: 8px 12px;
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 90px;
}

.download-button:hover {
  background-color: var(--color-main5);
}

.download-button:disabled {
  background-color: var(--color-interactionInactive);
  cursor: not-allowed;
}

.generating {
  color: var(--color-interactionInactive);
  font-size: 0.8rem;
  font-style: italic;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  background-color: var(--color-interactionDisable);
  border: 1px solid var(--color-lineNormal);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: var(--color-interactionInactive);
}

.pagination button.active {
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
  border-color: var(--color-main4);
}

.show-full-title {
  font-size: 0.8rem;
  padding: 0 3px;
  margin-left: 2px;
  background-color: var(--color-interactionDisable);
  border: none;
  cursor: pointer;
  vertical-align: middle;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.popup-content h3 {
  margin-top: 0;
}

.popup-content button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: var(--color-main4);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .archive-table {
    font-size: 14px;
  }

  .archive-table th, .archive-table td {
    padding: 8px;
  }

  .download-button {
    padding: 6px 10px;
    font-size: 12px;
  }
}