/* Terms.css */
.privacy-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  text-align: left; 
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.privacy-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left; 
}

.privacy-container h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left; 
}

.privacy-container p {
  margin-bottom: 15px;
  text-align: left; 
}

.privacy-container ol {
  padding-left: 20px;
  margin-bottom: 15px;
  text-align: left; 
}

.privacy-container li {
  margin-bottom: 10px;
  text-align: left; 
}

.privacy-container strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 15px;
  }

  .privacy-container h1 {
    font-size: 22px;
  }

  .privacy-container h2 {
    font-size: 18px;
  }

  .privacy-container p, .privacy-container li {
    font-size: 14px;
  }
}