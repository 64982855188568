.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 30px 0 30px;
  width: 100%;
  max-width: 600px;
}

.main-info{
  margin-bottom: 2.5rem;
}

.main-desc{
  font-weight: 900;
  font-size: 2rem;
}

.uploader-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.selector-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: var(--color-componentAlternative);
  cursor: pointer;
  transition: background-color 0.3s;
}

.selector-button.active {
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
}

.selector-button:hover {
  background-color: var(--color-componentNormal);
}

.selector-button.active:hover {
  background-color: var(--color-main5);
}