.header {
  background-color: var(--color-backgroundAlternative);
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
 
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
 
.user-section {
  display: flex;
  align-items: center;
}

.user-section a {
  height: 100%;
  display: flex;
  align-items: center;
}
 
.user-photo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
}
 
.token-count {
  font-size: 1rem;
  margin-right: 1rem;
  color: var(--color-labelNormal);
}

.token-count:hover {
  color: var(--color-main4);
  font-weight: bold;
}
 
.test-button {
  margin-right: 20px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--color-primaryNormal);
  color: var(--color-staticWhite);
}

.test-button:hover {
  background-color: var(--color-primaryStrong);
}

.login-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
}

.login-button:hover{
  background-color: var(--color-main5);
}

.logo {
  height: 2rem;
}

.user-menu-container {
  height: 2rem;
  position: relative;
}

.user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-backgroundNormal);
  border: 1px solid var(--color-lineNormal);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 150px;
}

.menu-item {
  display: block;
  padding: 10px 15px;
  color: var(--color-labelNormal);
  text-decoration: none;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: var(--color-backgroundAlternative);
}

button.menu-item {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
}