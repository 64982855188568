/* 레이아웃 */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.add-token-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: var(--color-backgroundNormal);
  width: 100%;
  max-width: 1200px;
}

/* 현재 토큰 표시 */
.current-tokens-container {
  display: flex;
  align-items: center;
  background-color: var(--color-main3);
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

.current-tokens-text {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 100%;
}

.current-tokens-text span:first-child {
  font-size: 14px;
  color: var(--color-labelNeutral);
  margin-bottom: 5px;
}

.current-token-amount {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-main6);
}

/* 옵션 그리드 */
.options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
}

/* 개별 옵션 */
.option {
  background-color: var(--color-backgroundNormal);
  border: 2px solid var(--color-lineNormal);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.option.selected {
  background-color: var(--color-main4);
  border-color: var(--color-main5);
}

.token-amount {
  font-size: 24px;
  color: var(--color-main6);
  font-weight: bold;
  margin-bottom: 10px;
}

.option.selected .token-amount,
.option.selected .price {
  color: var(--color-staticWhite);
}

.price {
  font-size: 18px;
  margin-bottom: 10px;
}

.bonus {
  font-size: 16px;
  color: var(--color-statusPositive);
  font-weight: bold;
}

.option.selected .bonus {
  color: var(--color-accentLime);
}

/* 결제 버튼 */
.pay-button {
  padding: 15px 30px;
  background-color: var(--color-main4);
  color: var(--color-staticWhite);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.pay-button:hover {
  background-color: var(--color-main5);
}

.pay-button:disabled {
  background-color: var(--color-interactionDisable);
  cursor: not-allowed;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .add-token-container {
    padding: 10px;
  }

  .current-tokens-container {
    padding: 10px 15px;
  }

  .options {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }

  .option {
    padding: 15px;
  }

  .token-amount {
    font-size: 20px;
  }

  .price {
    font-size: 16px;
  }

  .bonus {
    font-size: 14px;
  }

  .pay-button {
    padding: 12px 20px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .add-token-container {
    padding: 5px;
  }

  .current-tokens-container {
    padding: 8px 12px;
  }

  .options {
    grid-template-columns: 1fr;
  }

  .option {
    padding: 12px;
  }

  .token-amount {
    font-size: 18px;
  }

  .price {
    font-size: 14px;
  }

  .bonus {
    font-size: 12px;
  }

  .pay-button {
    padding: 10px 15px;
    font-size: 14px;
  }
}