.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--color-staticWhite);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center; /* 내용 중앙 정렬을 위해 추가 */
}

.popup-content h2 {
  margin-top: 0;
  color: var(--color-labelStrong);
}

.popup-buttons {
  display: flex;
  justify-content: center; /* flex-end에서 center로 변경 */
  margin-top: 20px;
  gap: 20px; /* 버튼 사이의 간격을 위해 추가 */
}

.popup-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* 호버 효과를 위해 추가 */
}

.confirm-button {
  background-color: var(--color-statusPositive);
  color: white;
}

.cancel-button {
  background-color: var(--color-statusDestructive);
  color: white;
}

/* 호버 효과 추가 */
.confirm-button:hover {
  background-color: #45a049;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.error-message {
  color: var(--color-statusDestructive);
  font-size: 0.9em;
  font-weight: 600;
  margin-left: 5px;
}