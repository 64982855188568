.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  font-size: 1rem;
  color: var(--color-labelNormal);
  overflow: hidden;
}

button {
  font-size: 1rem;
}

body {
  overflow: hidden;
}