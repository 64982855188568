/* Footer.css */
.footer {
  background-color: var(--color-inverseBackground);
  color: var(--color-labelDisable);
  padding: 20px 0;
  font-size: 0.75rem;
  justify-content: center;
  align-items: center;
}

.footer-content {
  max-width: 930px;
  margin: 0 auto;
  padding: 0 67px;
  text-align: left;
}

.footer-links {
  margin-top: 1.5rem;
}

.footer-links a {
  color: var(--color-labelDisable);
  text-decoration: none;
  margin-right: 16px;
}

.footer-links a:hover {
  color: var(--color-staticWhite);
  text-decoration: underline;
}

.footer-company {
  padding-bottom: 1rem;
}

.footer-company strong {
  color: var(--color-staticWhite);
  font-size: 1rem;
}

.footer-info p {
  margin: 4px 0;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }

  .footer-content {
    padding: 0 15px;
  }

  .footer-links a {
    display: block;
    margin-bottom: 10px;
  }
}