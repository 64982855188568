/* .main-container{
  height: calc(100vh - 68px);
} */

.file-uploader {
  border: 2px dashed var(--color-lineNormal);
  padding: 20px;
  width: 560px;
  text-align: center;
  cursor: pointer;
}

.file-uploader:hover,
.file-uploader.drag-active {
  border-color: var(--color-main4);
  color: var(--color-main4);
}

.progress-bar-container {
  width: 100%;
  background-color: var(--color-backgroundAlternative);
  margin-top: 10px;
}

.progress-bar {
  height: 20px;
  background-color: var(--color-main4);
  text-align: center;
  line-height: 20px;
  color: var(--color-staticWhite);
}